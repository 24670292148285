import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Portal } from 'react-portal';
import { useQueryClient } from 'react-query';
import { animated, SpringValue, useSpring } from 'react-spring';

import { ReactComponent as Particles } from 'images/event-guide/fuel_particles.svg';
import { Category, Coupon } from 'types';

import { fireCardAwardConfetti } from '../../../../utils/filreConfetti';
import hapticWebview from '../../../../utils/hapticWebview';
import setStatusBarWebview from '../../../../utils/setStatusBarWebview';
import FuelCardBackSide from '../FuelCardBackSide/FuelCardBackSide';

import styles from './FuelClonedCard.module.scss';

interface Props {
  isCardFlip: boolean;
  miniCardClientRect: { x: number; y: number; width: number; height: number };
  coupon: Coupon;
  openPhoneNumberInputPopup: () => void;
  category: Category;
}

const MiddleStepCardWidth = window.innerWidth * 0.82;
const MiddleStepCardHeight = window.innerHeight * 0.52;
const MiddleStepLeft = window.innerWidth * 0.09;
const MiddleStepTop = window.innerWidth * 0.4;

const FuelClonedCard: React.FC<Props> = ({ isCardFlip, miniCardClientRect, coupon, openPhoneNumberInputPopup, category }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [showParticle, setShowParticle] = useState(false);
  const queryClient = useQueryClient();

  const miniCardLeft = miniCardClientRect.x;
  const miniCardTop = miniCardClientRect.y;

  const cloneCardStyles = useSpring<{
    width: SpringValue<number>;
    height: SpringValue<number>;
    x: SpringValue<number>;
    y: SpringValue<number>;
    imageWidth: SpringValue<number>;
    imageX: SpringValue<number>;
    imageY: SpringValue<number>;
    contentOpacity: SpringValue<number>;
    contentScale: SpringValue<number>;
    contentX: SpringValue<number>;
    contentY: SpringValue<number>;
    borderRadius: SpringValue<number>;
  }>({
    to: async (animate) => {
      await animate({
        to: {
          width: MiddleStepCardWidth,
          height: MiddleStepCardHeight,
          x: -miniCardLeft + MiddleStepLeft,
          y: -miniCardTop + MiddleStepTop,
          imageWidth: MiddleStepCardWidth * 0.7,
          imageX: MiddleStepCardWidth * 0.15,
          imageY: MiddleStepCardHeight * 0.11,
          contentX: -miniCardLeft + MiddleStepLeft + 32,
          contentY: -miniCardTop + MiddleStepTop + MiddleStepCardHeight * 0.75,
          contentScale: 1,
        },
      });
      await animate({
        to: {
          width: window.innerWidth,
          height: window.innerHeight,
          x: -miniCardLeft,
          y: -miniCardTop,
          imageWidth: window.innerWidth * 0.56,
          imageX: window.innerWidth * 0.22,
          imageY: window.innerHeight * 0.25,
          contentOpacity: 0,
          borderRadius: 0,
        },
      });
    },
    from: {
      width: miniCardClientRect.width,
      height: miniCardClientRect.height,
      x: 0,
      y: 0,
      imageWidth: miniCardClientRect.width * 0.68,
      imageX: miniCardClientRect.width * 0.16,
      imageY: miniCardClientRect.height * 0.11,
      contentOpacity: 1,
      contentScale: 0.4,
      contentX: -10,
      contentY: miniCardClientRect.height * 0.5,
      borderRadius: 8,
    },
    onRest: (value) => {
      if (isCardFlip && value.value.width === window.innerWidth) {
        queryClient.invalidateQueries(`/gift_coupon_event/target/`);
        setFadeOut(true);
        setStatusBarWebview({ color: '#FFFFFF', content_brightness: 'dark' });
      }
    },
    cancel: fadeOut,
  });

  useEffect(() => {
    if (fadeOut) {
      hapticWebview('interact');
      fireCardAwardConfetti(category, () => {
        setShowParticle(true);
      });

      setTimeout(() => {
        openPhoneNumberInputPopup();
      }, 500);
    }
  }, [fadeOut]);

  return (
    <Portal>
      <animated.div
        style={{
          width: cloneCardStyles.width,
          height: cloneCardStyles.height,
          x: cloneCardStyles.x,
          y: cloneCardStyles.y,
          left: miniCardClientRect.x,
          top: miniCardClientRect.y,
          borderRadius: cloneCardStyles.borderRadius,
        }}
        className={classNames(styles.cloneCard)}
      >
        <div className={classNames(styles.backSideWrapper, { [styles.show]: fadeOut })}>
          <FuelCardBackSide coupon={coupon} />
        </div>
        <animated.div style={{ width: cloneCardStyles.imageWidth, x: cloneCardStyles.imageX, y: cloneCardStyles.imageY }}>
          <img src={coupon.image_url} className={styles.fuel} />
        </animated.div>
        <Particles className={classNames(styles.particle, { [styles.show]: showParticle })} />
      </animated.div>
      <animated.div
        style={{
          opacity: cloneCardStyles.contentOpacity,
          scale: cloneCardStyles.contentScale,
          x: cloneCardStyles.contentX,
          y: cloneCardStyles.contentY,
          left: miniCardLeft,
          top: miniCardTop,
        }}
        className={classNames(styles.cloneContent)}
      >
        <div className={styles.info}>주유권</div>
        <div className={styles.liter}>{coupon.name}</div>
      </animated.div>
    </Portal>
  );
};

export default FuelClonedCard;
