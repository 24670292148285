import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Toast from './components/Toast/Toast';
import FlipCardPage from './pages/FlipCardPage/FlipCardPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/card" exact component={FlipCardPage} />
      </Switch>
      <Toast />
    </Router>
  );
}

export default App;
