export function getTextSize(text: string | null, style?: Partial<CSSStyleDeclaration>) {
  const span = document.createElement('span');

  if (style) {
    for (const key in style) {
      span.style[key] = style[key] as string;
    }
  }

  span.innerText = text || '';

  document.body.appendChild(span);

  const size = {
    width: span.offsetWidth,
    height: span.offsetHeight,
  };

  document.body.removeChild(span);

  return size;
}
