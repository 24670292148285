import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import pxios from './apis/pxios';
import App from './App';
import './index.scss';
import { store } from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: url }) => {
        const { data } = await pxios.get(`${url}`);
        return data;
      },
      retry: false,
    },
  },
});

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://083ac2f3d5594e13a5e154fcf22c9ab7@o44449.ingest.sentry.io/1530258',
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 0.2,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
