export interface statusData {
  color: string;
  content_brightness: 'light' | 'dark';
}

export default function setStatusBarWebview(shareData: statusData) {
  if (window.Android && window.Android.setStatusBar) {
    window.Android.setStatusBar(JSON.stringify(shareData));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.setStatusBar) {
    window.webkit.messageHandlers.setStatusBar.postMessage(shareData);
  }
}
