(function main(global, module, isWorker, workerSize) {
  const fuelSvgMap = {
    coin1: {
      type: 'coin',
      items: [
        {
          color: '#FF822F',
          path: 'M21.0796 23.6503C13.656 21.0148 7.56124 15.5742 4.10327 8.49609L21.0796 23.6503Z',
        },
        {
          color: '#FF822F',
          path: 'M28.4836 22.4696L25.7376 25.5748C24.4545 27.0376 21.9651 27.4483 18.6289 26.4602C11.2038 23.827 5.10831 18.3857 1.65256 11.306C0.369394 8.09804 0.433549 5.59582 1.72955 4.13301L4.10339 1.42554',
        },
        {
          color: '#FDAC50',
          path: 'M6.3362 9.20173C9.13797 14.9601 14.0929 19.385 20.1303 21.5201C22.6966 22.2516 24.5316 22.0334 25.6351 21.0582C26.482 19.852 26.4948 17.9273 25.4811 15.5278C22.6693 9.77696 17.718 5.35537 11.687 3.20935C9.19764 2.47794 7.28575 2.70889 6.18222 3.67127C5.38665 4.89028 5.38665 6.8022 6.3362 9.20173Z',
        },
        {
          color: '#FF822F',
          path: 'M11.3768 17.7644C9.59608 16.4896 8.11736 14.8617 7.03136 12.9807C5.94536 11.0997 5.27496 9.00522 5.06128 6.82568',
        },
      ],
    },
    coin2: {
      type: 'coin',
      items: [
        {
          color: '#FF822F',
          path: 'M15.7331 18.2486L13.5375 19.5162C12.5005 20.1149 11.028 19.959 9.36804 18.9052C7.58997 17.6278 6.11309 15.9993 5.02735 14.1188C3.94161 12.2382 3.26973 10.145 3.05254 7.96644C2.97478 5.99919 3.57118 4.64877 4.60812 4.0501L6.55542 2.92582',
        },
        {
          color: '#FFC25D',
          path: 'M6.57761 2.93134C7.61455 2.33266 9.08227 2.49135 10.7471 3.54232C12.5251 4.81967 14.002 6.44817 15.0877 8.32873C16.1734 10.2093 16.8454 12.3026 17.0626 14.4811C17.1403 16.4483 16.5439 17.7987 15.5069 18.3974C14.47 18.9961 13.0023 18.8374 11.3424 17.7836C9.56168 16.5088 8.08296 14.8809 6.99696 12.9999C5.91095 11.1189 5.24056 9.02442 5.02688 6.84489C4.94425 4.88044 5.54554 3.5272 6.57761 2.93134Z',
        },
        {
          color: '#FDAC50',
          path: 'M6.15609 7.56377C6.3301 9.33364 6.87474 11.0344 7.75665 12.5619C8.63856 14.0894 9.83916 15.4115 11.2849 16.4471C12.531 17.2338 13.6432 17.4064 14.4654 17.0428C15.1913 16.5126 15.6028 15.4602 15.5398 13.9906C15.3658 12.2207 14.8211 10.5199 13.9392 8.99242C13.0573 7.4649 11.8567 6.14286 10.4109 5.10723C9.16976 4.3178 8.05531 4.15263 7.23047 4.51157C6.5072 5.04643 6.09789 6.09135 6.15609 7.56377Z',
        },
        {
          color: '#FF822F',
          path: 'M10.4107 5.10749C11.8564 6.14312 13.0571 7.46517 13.939 8.99268C14.8209 10.5202 15.3655 12.221 15.5395 13.9909C15.6026 15.4605 15.1911 16.5128 14.4651 17.0431C14.5326 17.0102 14.5977 16.9726 14.6599 16.9306C15.5021 16.4444 15.9915 15.3471 15.913 13.7382C15.739 11.9684 15.1943 10.2676 14.3124 8.74008C13.4305 7.21257 12.2299 5.89045 10.7841 4.85482C9.43813 3.99629 8.25458 3.84636 7.3929 4.34385C7.32552 4.37687 7.26043 4.41445 7.19815 4.45629C8.05506 4.15289 9.16951 4.31806 10.4107 5.10749Z',
        },
      ],
    },
    coin3: {
      type: 'coin',
      items: [
        {
          color: '#FF822F',
          path: 'M17.9066 6.85352C17.2558 9.28994 15.9738 11.5116 14.1901 13.2943C12.4063 15.077 10.1839 16.3576 7.74707 17.0069',
        },
        {
          color: '#FF822F',
          path: 'M16.4778 1.24268L19.0131 3.75366C20.2291 4.96963 20.6972 6.83615 20.2291 9.15257C19.5805 11.5891 18.3 13.8111 16.5172 15.594C14.7343 17.3769 12.5123 18.6574 10.0757 19.3059C7.76539 19.7984 5.87456 19.3059 4.6829 18.09L2.42114 15.8282',
        },
        {
          color: '#FFC25D',
          path: 'M2.39078 15.8584C1.17481 14.6424 0.712743 12.7759 1.17481 10.4656C1.82413 8.02877 3.10469 5.80636 4.88737 4.02262C6.67004 2.23888 8.89172 0.956943 11.3281 0.306166C13.6446 -0.186302 15.5354 0.306166 16.7271 1.52214C17.9187 2.73811 18.4051 4.60462 17.9127 6.91497C17.2619 9.35038 15.9806 11.5712 14.198 13.3537C12.4155 15.1363 10.1947 16.4175 7.75927 17.0683C5.47324 17.5364 3.58243 17.0501 2.39078 15.8584Z',
        },
        {
          color: '#FDAC50',
          path: 'M8.1485 15.4509C10.126 14.9205 11.9291 13.8788 13.3763 12.4305C14.8235 10.9822 15.8639 9.17846 16.3928 7.20055C16.7637 5.46779 16.4536 4.02686 15.6449 3.05409C14.6722 2.24547 13.213 1.93541 11.4985 2.30628C9.51173 2.83464 7.69993 3.87884 6.24677 5.33307C4.7936 6.7873 3.75074 8.59987 3.22383 10.587C2.85904 12.3198 3.16911 13.7607 3.97773 14.7335C4.95051 15.53 6.39142 15.8461 8.1485 15.4509Z',
        },
        {
          color: '#FF822F',
          path: 'M3.22382 10.5868C3.75423 8.60929 4.79595 6.80629 6.24423 5.35908C7.6925 3.91187 9.49624 2.87148 11.4741 2.34253C13.2069 1.97166 14.6479 2.28172 15.6207 3.09034C15.5538 3.0113 15.4808 2.93226 15.4078 2.8593C14.4411 1.88653 12.9029 1.49136 11.0242 1.89263C9.04561 2.42078 7.24144 3.46174 5.79388 4.91036C4.34632 6.35899 3.3067 8.16397 2.78 10.143C2.37873 12.0156 2.77998 13.5538 3.74059 14.5205C3.81963 14.5934 3.8926 14.6664 3.97772 14.7333C3.1691 13.7483 2.85903 12.3074 3.22382 10.5868Z',
        },
      ],
    },
    particle1: {
      type: 'particle',
      items: [
        {
          color: '#FFC14F',
          path: 'M11.6869 3.20941C17.7179 5.35543 22.6692 9.77702 25.481 15.5278C26.4947 17.9274 26.4819 19.8521 25.635 21.0583C25.731 20.9794 25.821 20.8936 25.9045 20.8016C26.9567 19.6211 27.0593 17.5809 25.9045 14.9761C24.5618 12.1001 22.6243 9.54161 20.22 7.46955C17.9117 5.32925 15.1731 3.70622 12.1874 2.70895C9.46704 1.90056 7.46529 2.23418 6.41309 3.42753C6.32553 3.51246 6.24804 3.60722 6.18213 3.70986C7.28566 2.70899 9.23605 2.478 11.6869 3.20941Z',
        },
      ],
    },
    particle2: {
      type: 'particle',
      items: [
        {
          color: '#FF822F',
          path: 'M0 1.74934L4.95178 0C5.64676 2.38555 5.16896 6.05784 15 7.22893C12.7703 10.2362 7.10909 12 7.10909 12C5.24373 10.9167 3.63986 9.43678 2.41129 7.66529C1.18272 5.8938 0.359493 3.87404 0 1.74934Z',
        },
      ],
    },
    particle3: {
      type: 'particle',
      items: [
        {
          color: '#FF822F',
          path: 'M0 11.6035C2.08588 10.5979 9.60291 7.12971 14.0108 0C17.3036 1.94681 19.3895 5.15716 16.9101 8.26432C14.4306 11.3715 9.90472 16 9.90472 16C7.60894 14.8912 4.76211 14.7236 0 11.6035Z',
        },
      ],
    },
    particle4: {
      type: 'particle',
      items: [
        {
          color: '#7251F4',
          path: 'M20.5806 0C15.4314 6.44315 8.12333 10.8164 0 12.3159C1.68073 16.1816 7.78612 26 7.78612 26C11.6964 24.3237 24.1821 10.4685 26 5.47378C24.3536 3.52379 20.5806 0 20.5806 0Z',
        },
      ],
    },
    particle5: {
      type: 'particle',
      items: [
        {
          color: '#0D65E0',
          path: 'M0 5.08265C0.638218 3.86482 4.82916 0 4.82916 0C7.09483 1.62041 9.2648 7.5888 2.06357 14C3.11663 10.4371 2.52096 7.47804 0 5.08265Z',
        },
      ],
    },
    particle6: {
      type: 'particle',
      items: [
        {
          color: '#FFB238',
          path: 'M0 0C1.36574 1.83333 5.30901 5.5 2.21205 9.16667C3.56519 9.71225 4.8878 10.3242 6.17461 11C8.54062 8.965 11.3683 6.61833 5.63607 0C3.8039 0.563548 1.83217 0.563548 0 0Z',
        },
      ],
    },
  };
  const campingSvgMap = {
    coin1: {
      type: 'coin',
      items: [
        {
          color: '#FFE4B3',
          path: 'M12.941279999999999,20.5882a5.29412,5.29412 0 1,0 10.58824,0a5.29412,5.29412 0 1,0 -10.58824,0',
        },
        {
          color: '#FFB800',
          path: 'M38.1278 24.6382C39.9394 22.824 40.597 19.819 39.3805 17.5562C38.164 15.2934 35.4745 14.228 32.9363 14.2411C30.3981 14.2543 23.8914 16.2764 23.8914 16.2764C26.137 15.0427 28.1656 13.3077 29.3624 11.0217C30.5592 8.73584 30.8387 5.91557 29.7339 3.59668C28.6292 1.27779 26.022 -0.338505 23.487 0.0606215C20.9521 0.459748 19.0616 2.65 18.2265 5.05465C17.3914 7.4593 17.4538 10.0685 17.5262 12.6117C17.5262 12.6117 13.4985 8.57751 11.322 7.2284C9.14542 5.87929 6.34089 5.4109 3.96378 6.36418C1.58666 7.31746 -0.201928 9.81448 0.018358 12.3742C0.238644 14.9338 2.30013 16.9921 4.62135 17.9817C6.94257 18.9713 9.55312 19.0867 12.0913 19.1857C12.0913 19.1857 7.48835 21.6332 5.38412 23.1143C3.2799 24.5953 1.76749 26.9934 1.70174 29.563C1.63598 32.1325 3.27333 34.7516 5.70963 35.5433C8.14592 36.3349 10.8288 35.2464 12.6536 33.4817C14.4783 31.7169 15.6126 29.3618 16.691 27.0627C16.691 27.0627 17.3486 32.2678 18.2791 34.6494C19.2095 37.0309 21.1658 39.1057 23.6383 39.7819C26.1107 40.4581 29.0336 39.5378 30.3849 37.3542C31.7362 35.1705 31.3253 32.2975 30.0562 30.0973C28.787 27.8972 24.2663 23.3386 24.2663 23.3386C26.1732 25.0538 28.4911 26.3931 31.0261 26.7724C33.561 27.1517 36.3129 26.4557 38.1278 24.6382ZM19.7783 24.7042C18.9326 24.8944 18.0497 24.8287 17.2411 24.5155C16.4326 24.2023 15.7348 23.6556 15.2359 22.9446C14.737 22.2336 14.4594 21.3901 14.4383 20.5208C14.4172 19.6515 14.6535 18.7955 15.1172 18.0609C15.581 17.3263 16.2515 16.7462 17.0438 16.3938C17.8362 16.0415 18.7149 15.9328 19.5689 16.0814C20.4228 16.23 21.2137 16.6294 21.8415 17.2289C22.4692 17.8284 22.9057 18.6012 23.0958 19.4496C23.222 20.0131 23.2363 20.5961 23.1379 21.1653C23.0395 21.7344 22.8303 22.2785 22.5222 22.7665C22.2141 23.2544 21.8132 23.6767 21.3424 24.0092C20.8716 24.3417 20.3401 24.5779 19.7783 24.7042V24.7042Z',
        },
      ],
    },
    coin2: {
      type: 'coin',
      items: [
        {
          color: '#9BCD49',
          path: 'M0.0778863 25.3949C2.95003 24.8921 5.69132 23.8153 8.13761 22.2289C10.5839 20.6426 12.6849 18.5793 14.3147 16.1627C17.5506 11.3215 17.4031 0.719788 17.4031 0.719788C12.2519 2.43643 7.78801 5.76077 4.6689 10.2031C1.54979 14.6454 -0.059556 19.9708 0.0778863 25.3949V25.3949Z',
        },
      ],
    },
    coin3: {
      type: 'coin',
      items: [
        { color: '#FFE4B3', path: 'M8.73537,13.897a3.57353,3.57353 0 1,0 7.14706,0a3.57353,3.57353 0 1,0 -7.14706,0' },
        {
          color: '#FFB800',
          path: 'M25.7363 16.6308C26.9591 15.4062 27.403 13.3778 26.5818 11.8504C25.7607 10.323 23.9453 9.60387 22.232 9.61278C20.5187 9.62168 16.1267 10.9865 16.1267 10.9865C17.6425 10.1538 19.0118 8.98267 19.8196 7.43968C20.6275 5.8967 20.8161 3.99301 20.0704 2.42776C19.3247 0.862509 17.5648 -0.228491 15.8537 0.0409195C14.1427 0.31033 12.8666 1.78875 12.3029 3.41189C11.7392 5.03503 11.7813 6.79622 11.8302 8.51287C11.8302 8.51287 9.11151 5.78982 7.64234 4.87917C6.17316 3.96852 4.2801 3.65235 2.67555 4.29582C1.071 4.93929 -0.136301 6.62477 0.0123916 8.35256C0.161085 10.0803 1.55259 11.4697 3.11941 12.1377C4.68624 12.8056 6.44835 12.8835 8.16165 12.9503C8.16165 12.9503 5.05463 14.6024 3.63428 15.6021C2.21393 16.6019 1.19306 18.2205 1.14867 19.955C1.10429 21.6895 2.2095 23.4573 3.854 23.9917C5.4985 24.5261 7.30945 23.7913 8.54116 22.6001C9.77287 21.4089 10.5385 19.8192 11.2665 18.2673C11.2665 18.2673 11.7103 21.7808 12.3384 23.3883C12.9664 24.9959 14.2869 26.3964 15.9558 26.8528C17.6248 27.3092 19.5977 26.688 20.5098 25.2141C21.422 23.7401 21.1446 21.8008 20.2879 20.3157C19.4313 18.8306 16.3797 15.7535 16.3797 15.7535C17.6669 16.9113 19.2315 17.8153 20.9426 18.0714C22.6537 18.3274 24.5112 17.8576 25.7363 16.6308ZM13.3504 16.6753C12.7795 16.8037 12.1835 16.7594 11.6378 16.548C11.092 16.3366 10.621 15.9676 10.2842 15.4876C9.94746 15.0077 9.76012 14.4383 9.74586 13.8515C9.73161 13.2648 9.89109 12.6869 10.2041 12.1911C10.5172 11.6953 10.9697 11.3037 11.5046 11.0658C12.0394 10.828 12.6326 10.7546 13.209 10.8549C13.7854 10.9553 14.3192 11.2248 14.743 11.6295C15.1667 12.0342 15.4614 12.5558 15.5896 13.1285C15.6749 13.5089 15.6845 13.9024 15.6181 14.2866C15.5517 14.6707 15.4104 15.038 15.2025 15.3674C14.9945 15.6967 14.7239 15.9818 14.4061 16.2062C14.0884 16.4306 13.7296 16.5901 13.3504 16.6753V16.6753Z',
        },
      ],
    },
    particle1: {
      type: 'particle',
      items: [
        {
          color: '#FFB020',
          path: 'M1.62232 4.50248C0.901715 4.69761 0.218477 5.21395 0.0420635 5.94344C-0.191161 6.90107 0.57877 7.90525 1.52811 8.15591C2.47746 8.40658 3.50307 8.04033 4.28348 7.43994C3.9441 8.06735 3.62116 8.71879 3.51351 9.42276C3.40587 10.1267 3.53745 10.9027 4.02035 11.4251C4.78431 12.2536 6.23897 12.1471 7.04329 11.359C7.84762 10.571 8.03897 9.29817 7.74445 8.20694C7.87417 8.5807 8.10201 8.91234 8.40393 9.16683C8.70585 9.42133 9.07065 9.58924 9.45975 9.65281C9.84886 9.71638 10.2478 9.67326 10.6145 9.52801C10.9812 9.38276 11.3021 9.14076 11.5431 8.82759C11.7842 8.51441 11.9365 8.14168 11.984 7.74877C12.0316 7.35587 11.9725 6.95737 11.8131 6.59537C11.6537 6.23337 11.3999 5.92132 11.0785 5.69217C10.7571 5.46302 10.3799 5.32528 9.98699 5.29351C10.4119 5.20233 10.8042 4.99725 11.1223 4.70007C11.4403 4.4029 11.6722 4.02474 11.7933 3.6058C11.9144 3.18687 11.9201 2.74282 11.8099 2.32088C11.6996 1.89893 11.4776 1.51487 11.1673 1.20952C10.857 0.904177 10.4701 0.68897 10.0477 0.586778C9.6253 0.484587 9.18325 0.499229 8.76849 0.629159C8.35373 0.759088 7.98178 0.999442 7.69222 1.32466C7.40266 1.64988 7.20631 2.04781 7.12403 2.47614C6.73981 1.40592 5.97734 0.386746 4.88149 0.0745388C3.78563 -0.237669 2.41467 0.461797 2.3145 1.59505C2.2233 2.63374 3.83946 4.44695 3.83946 4.44695C3.1054 4.3794 2.34292 4.31186 1.62232 4.50248Z',
        },
      ],
    },
    particle2: {
      type: 'particle',
      items: [
        {
          color: '#9BCD49',
          path: 'M0.267734 16.9919C2.42682 16.8314 4.53118 16.2363 6.45481 15.2422C8.37845 14.2481 10.0818 12.8754 11.4628 11.2064C14.2079 7.86058 14.874 0 14.874 0C10.9421 0.891066 7.40052 3.02318 4.77091 6.08227C2.14131 9.14135 0.562744 12.9657 0.267734 16.9919V16.9919Z',
        },
      ],
    },
    particle3: {
      type: 'particle',
      items: [
        {
          color: '#F96A6A',
          path: 'M2.83906 7.87934C1.578 8.22082 0.382334 9.12441 0.073611 10.401C-0.334532 12.0769 1.01285 13.8342 2.6742 14.2729C4.33555 14.7115 6.13037 14.0706 7.49608 13.0199C6.90218 14.1179 6.33702 15.2579 6.14865 16.4898C5.96028 17.7218 6.19055 19.0798 7.03561 19.9939C8.37254 21.4439 10.9182 21.2574 12.3258 19.8783C13.7333 18.4993 14.0682 16.2718 13.5528 14.3622C13.7798 15.0162 14.1785 15.5966 14.7069 16.042C15.2352 16.4873 15.8736 16.7812 16.5546 16.8924C17.2355 17.0037 17.9337 16.9282 18.5754 16.674C19.2172 16.4198 19.7786 15.9963 20.2005 15.4483C20.6223 14.9002 20.8889 14.2479 20.9721 13.5604C21.0552 12.8728 20.9519 12.1754 20.6729 11.5419C20.394 10.9084 19.9498 10.3623 19.3873 9.96129C18.8248 9.56028 18.1649 9.31925 17.4772 9.26364C18.2208 9.10409 18.9074 8.74519 19.464 8.22513C20.0206 7.70507 20.4264 7.0433 20.6383 6.31016C20.8501 5.57702 20.8602 4.79994 20.6673 4.06154C20.4744 3.32313 20.0858 2.65102 19.5427 2.11666C18.9997 1.58231 18.3226 1.2057 17.5835 1.02686C16.8443 0.848027 16.0707 0.873651 15.3449 1.10103C14.619 1.3284 13.9681 1.74902 13.4614 2.31816C12.9547 2.88729 12.611 3.58367 12.4671 4.33324C11.7947 2.46037 10.4603 0.676806 8.5426 0.130443C6.62485 -0.41592 4.22566 0.808145 4.05037 2.79134C3.89078 4.60905 6.71905 7.78216 6.71905 7.78216C5.43445 7.66395 4.10011 7.54575 2.83906 7.87934Z',
        },
      ],
    },
    particle4: {
      type: 'particle',
      items: [
        {
          color: '#9BCD49',
          path: 'M22.9128 27C23.1961 23.5901 22.7874 20.1582 21.7112 16.9098C20.6349 13.6614 18.9132 10.6632 16.6493 8.09487C12.1053 2.98294 0 0 0 0C0.418958 6.35776 2.87161 12.4131 6.99665 17.274C11.1217 22.1348 16.7011 25.5442 22.9128 27V27Z',
        },
      ],
    },
    particle5: {
      type: 'particle',
      items: [
        {
          color: '#00B493',
          path: 'M0.0424248 10.1247C-0.446331 6.25663 3.44924 0 3.44924 0C6.38441 2.60839 8.26955 5.95957 8.82714 9.5601C9.38473 13.1606 8.58527 16.8202 6.54648 20C4.74678 18.8155 3.24311 17.3303 2.12553 15.6334C1.00795 13.9366 0.299455 12.063 0.0424248 10.1247V10.1247Z',
        },
      ],
    },
    particle6: {
      type: 'particle',
      items: [
        {
          color: '#E47BE9',
          path: 'M4.45266 0.131796C1.47084 0.761871 -0.475961 3.44926 0.101393 6.13666C0.678746 8.82405 3.56551 10.495 6.54734 9.86838C9.52916 9.24179 11.476 6.5544 10.8986 3.86352C10.3213 1.17265 7.43449 -0.494798 4.45266 0.131796Z',
        },
      ],
    },
  };
  const baskinRobbinsSvgMap = {
    coin1: {
      type: 'coin',
      items: [],
    },
    coin2: {
      type: 'coin',
      items: [],
    },
    coin3: {
      type: 'coin',
      items: [],
    },
    particle1: {
      type: 'particle',
      items: [
        {
          color: '#6B5FFF',
          path: 'M5.93532 10.9944C3.37663 10.8706 1.8787 8.71977 2.00771 3.98488C2.58108 3.39689 3.63465 1.43947 5.03942 1.06037C7.18958 0.480116 10.0565 4.20923 9.99915 6.81651C10.0008 7.3835 9.89535 7.94491 9.68922 8.46642C9.48309 8.98793 9.18063 9.4586 8.80027 9.84963C8.41992 10.2407 7.96966 10.5439 7.47707 10.7407C6.98448 10.9375 6.45987 11.0238 5.93532 10.9944V10.9944Z',
        },
      ],
    },
    particle2: {
      type: 'particle',
      items: [
        {
          color: '#67E2DA',
          path: 'M13.3951 3.81681C15.0916 7.4545 13.0957 12.2614 8.95979 14.3047C8.11512 14.7931 7.16316 15.032 6.20264 14.9966C5.24211 14.9612 4.30797 14.6528 3.49698 14.1035C2.686 13.5541 2.02769 12.7837 1.59032 11.8721C1.15296 10.9605 0.952421 9.94094 1.00952 8.91903C1.00952 5.74196 4.41364 2.18695 8.19471 1.2421C9.20576 0.868989 10.3134 0.92897 11.2837 1.40938C12.254 1.88978 13.0111 2.75301 13.3951 3.81681V3.81681Z',
        },
      ],
    },
    particle3: {
      type: 'particle',
      items: [
        {
          color: '#FFB015',
          path: 'M11.6435 0.00804426C12.8588 -0.0430786 14.072 0.149884 15.2092 0.575106C16.3463 1.00033 17.3837 1.64899 18.2583 2.48157C19.1329 3.31416 19.8263 4.31333 20.2965 5.41822C20.7667 6.52311 21.0037 7.71059 20.9932 8.90855C21.2017 15.7551 16.6139 19.8973 9.17591 20C2.85013 20 0.139028 17.6379 2.85933e-08 11.613C-0.00021585 8.55874 1.22197 5.62757 3.40167 3.45513C5.58136 1.28269 8.54273 0.0440823 11.6435 0.00804426V0.00804426Z',
        },
      ],
    },
    particle4: {
      type: 'particle',
      items: [
        {
          color: '#FF647D',
          path: 'M21.9762 6.41345C24.8474 12.1342 21.4695 19.7124 14.4699 22.9071C13.0418 23.6741 11.4327 24.0496 9.80887 23.9947C8.185 23.9398 6.60537 23.4565 5.23347 22.5948C3.86158 21.7331 2.74726 20.5244 2.0056 19.0935C1.26395 17.6626 0.9219 16.0616 1.01498 14.456C1.01498 9.44103 6.77612 3.86885 13.1752 2.38294C14.8839 1.79514 16.7564 1.88683 18.3982 2.63869C20.04 3.39055 21.3227 4.74385 21.9762 6.41345V6.41345Z',
        },
      ],
    },
    particle5: {
      type: 'particle',
      items: [
        {
          color: '#87421C',
          path: 'M9 11.1144C9 14.4039 7.35929 15.7196 5.53424 15.9827C3.06398 16.3446 -0.12525 10.95 0.003794 6.87117C0.132838 3.38439 1.44172 2.16717 3.11928 2.0027C4.55908 1.94396 5.9595 2.84512 7.04692 4.52999C8.13435 6.21487 8.83067 8.5625 9 11.1144Z',
        },
      ],
    },
    particle6: {
      type: 'particle',
      items: [
        {
          color: '#67E2DA',
          path: 'M8.11095 7.8887C8.73902 7.27012 9.26695 6.55755 9.67567 5.77673C10.4881 4.23729 10.6272 2.67527 9.30609 1.3071C7.98495 -0.0610807 6.42309 -0.00802966 4.84072 0.706561C4.22561 1.02863 3.64971 1.42088 3.12462 1.87541C2.63074 2.3693 2.52491 2.47512 2.423 2.57703C0.356842 4.97935 -0.132773 8.041 1.3294 9.32287C2.79157 10.6047 6.04141 9.90351 8.11095 7.8887Z',
        },
      ],
    },
  };

  var canUseWorker = !!(
    global.Worker &&
    global.Blob &&
    global.Promise &&
    global.OffscreenCanvas &&
    global.OffscreenCanvasRenderingContext2D &&
    global.HTMLCanvasElement &&
    global.HTMLCanvasElement.prototype.transferControlToOffscreen &&
    global.URL &&
    global.URL.createObjectURL
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function noop() {}

  // create a promise if it exists, otherwise, just
  // call the function directly
  function promise(func) {
    var ModulePromise = module.exports.Promise;
    var Prom = ModulePromise !== void 0 ? ModulePromise : global.Promise;

    if (typeof Prom === 'function') {
      return new Prom(func);
    }

    func(noop, noop);

    return null;
  }

  var raf = (function () {
    var TIME = Math.floor(1000 / 60);
    var frame, cancel;
    var frames = {};
    var lastFrameTime = 0;

    if (typeof requestAnimationFrame === 'function' && typeof cancelAnimationFrame === 'function') {
      frame = function (cb) {
        var id = Math.random();

        frames[id] = requestAnimationFrame(function onFrame(time) {
          if (lastFrameTime === time || lastFrameTime + TIME - 1 < time) {
            lastFrameTime = time;
            delete frames[id];

            cb();
          } else {
            frames[id] = requestAnimationFrame(onFrame);
          }
        });

        return id;
      };
      cancel = function (id) {
        if (frames[id]) {
          cancelAnimationFrame(frames[id]);
        }
      };
    } else {
      frame = function (cb) {
        return setTimeout(cb, TIME);
      };
      cancel = function (timer) {
        return clearTimeout(timer);
      };
    }

    return { frame: frame, cancel: cancel };
  })();

  var getWorker = (function () {
    var worker;
    var prom;
    var resolves = {};

    function decorate(worker) {
      function execute(options, callback) {
        worker.postMessage({ options: options || {}, callback: callback });
      }
      worker.init = function initWorker(canvas) {
        var offscreen = canvas.transferControlToOffscreen();
        worker.postMessage({ canvas: offscreen }, [offscreen]);
      };

      worker.fire = function fireWorker(options, size, done) {
        if (prom) {
          execute(options, null);
          return prom;
        }

        var id = Math.random().toString(36).slice(2);

        prom = promise(function (resolve) {
          function workerDone(msg) {
            if (msg.data.callback !== id) {
              return;
            }

            delete resolves[id];
            worker.removeEventListener('message', workerDone);

            prom = null;
            done();
            resolve();
          }

          worker.addEventListener('message', workerDone);
          execute(options, id);

          resolves[id] = workerDone.bind(null, { data: { callback: id } });
        });

        return prom;
      };

      worker.reset = function resetWorker() {
        worker.postMessage({ reset: true });

        for (var id in resolves) {
          resolves[id]();
          delete resolves[id];
        }
      };
    }

    return function () {
      if (worker) {
        return worker;
      }

      if (!isWorker && canUseWorker) {
        var code = [
          'var CONFETTI, SIZE = {}, module = {};',
          '(' + main.toString() + ')(this, module, true, SIZE);',
          'onmessage = function(msg) {',
          '  if (msg.data.options) {',
          '    CONFETTI(msg.data.options).then(function () {',
          '      if (msg.data.callback) {',
          '        postMessage({ callback: msg.data.callback });',
          '      }',
          '    });',
          '  } else if (msg.data.reset) {',
          '    CONFETTI.reset();',
          '  } else if (msg.data.resize) {',
          '    SIZE.width = msg.data.resize.width;',
          '    SIZE.height = msg.data.resize.height;',
          '  } else if (msg.data.canvas) {',
          '    SIZE.width = msg.data.canvas.width;',
          '    SIZE.height = msg.data.canvas.height;',
          '    CONFETTI = module.exports.create(msg.data.canvas);',
          '  }',
          '}',
        ].join('\n');
        try {
          worker = new Worker(URL.createObjectURL(new Blob([code])));
        } catch (e) {
          // eslint-disable-next-line no-console
          typeof console !== undefined && typeof console.warn === 'function' ? console.warn('🎊 Could not load worker', e) : null;

          return null;
        }

        decorate(worker);
      }

      return worker;
    };
  })();

  var defaults = {
    particleCount: 50,
    angle: 90,
    spread: 45,
    startVelocity: 45,
    decay: 0.9,
    gravity: 1,
    drift: 0,
    ticks: 200,
    x: 0.5,
    y: 0.5,
    shapes: ['particle1', 'coin1'],
    zIndex: 100,
    colors: ['#CED3F8', '#FF822F', '#0D65E0', '#FFC14F', '#7251F4'],
    disableForReducedMotion: false,
    scalar: 1,
    particleSize: 'normal',
  };

  function convert(val, transform) {
    return transform ? transform(val) : val;
  }

  function isOk(val) {
    return !(val === null || val === undefined);
  }

  function prop(options, name, transform) {
    return convert(options && isOk(options[name]) ? options[name] : defaults[name], transform);
  }

  function onlyPositiveInt(number) {
    return number < 0 ? 0 : Math.floor(number);
  }

  function randomInt(min, max) {
    // [min, max)
    return Math.floor(Math.random() * (max - min)) + min;
  }

  function toDecimal(str) {
    return parseInt(str, 16);
  }

  function colorsToRgb(colors) {
    return colors.map(hexToRgb);
  }

  function hexToRgb(str) {
    var val = String(str).replace(/[^0-9a-f]/gi, '');

    if (val.length < 6) {
      val = val[0] + val[0] + val[1] + val[1] + val[2] + val[2];
    }

    return {
      r: toDecimal(val.substring(0, 2)),
      g: toDecimal(val.substring(2, 4)),
      b: toDecimal(val.substring(4, 6)),
    };
  }

  function getOrigin(options) {
    var origin = prop(options, 'origin', Object);
    origin.x = prop(origin, 'x', Number);
    origin.y = prop(origin, 'y', Number);

    return origin;
  }

  function setCanvasWindowSize(canvas) {
    canvas.width = document.documentElement.clientWidth;
    canvas.height = document.documentElement.clientHeight;
  }

  function setCanvasRectSize(canvas) {
    const dpr = window.devicePixelRatio;
    var rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
  }

  function getCanvas(zIndex) {
    var canvas = document.createElement('canvas');

    canvas.style.position = 'fixed';
    canvas.style.top = '0px';
    canvas.style.left = '0px';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = zIndex;

    return canvas;
  }

  function coinPath(context, x, y, rotation, path, particleSize) {
    context.save();
    context.translate(x, y);
    if (particleSize === 'small') {
      context.scale(2, 2);
    }
    if (particleSize === 'big') {
      context.scale(1.3, 1.3);
    }
    context.fill(new Path2D(path));
    context.restore();
  }

  function path(context, x, y, scaleX, scaleY, rotation, path, particleSize) {
    context.save();
    context.translate(x, y);
    context.rotate(rotation);
    context.scale(scaleX, scaleY);
    if (particleSize === 'small') {
      context.scale(2, 2);
    }
    if (particleSize === 'big') {
      context.scale(1.3, 1.3);
    }
    context.fill(new Path2D(path));
    context.restore();
  }

  function randomPhysics(opts) {
    var radAngle = opts.angle * (Math.PI / 180);
    var radSpread = opts.spread * (Math.PI / 180);

    return {
      x: opts.x,
      y: opts.y,
      wobble: Math.random() * 10,
      wobbleSpeed: Math.min(0.11, Math.random() * 0.1 + 0.05),
      velocity: opts.startVelocity * 0.5 + Math.random() * opts.startVelocity,
      angle2D: -radAngle + (0.5 * radSpread - Math.random() * radSpread),
      tiltAngle: (Math.random() * (0.75 - 0.25) + 0.25) * Math.PI,
      color: opts.color,
      shape: opts.shape,
      tick: 0,
      totalTicks: opts.ticks,
      decay: opts.decay,
      drift: opts.drift,
      random: Math.random() + 2,
      tiltSin: 0,
      tiltCos: 0,
      wobbleX: 0,
      wobbleY: 0,
      gravity: opts.gravity * 3,
      ovalScalar: 0.6,
      scalar: opts.scalar,
      particleSize: opts.particleSize,
      category: opts.category,
    };
  }

  function updateFetti(context, fetti) {
    fetti.x += Math.cos(fetti.angle2D) * fetti.velocity + fetti.drift;
    fetti.y += Math.sin(fetti.angle2D) * fetti.velocity + fetti.gravity;
    fetti.wobble += fetti.wobbleSpeed;
    fetti.velocity *= fetti.decay;
    fetti.tiltAngle += 0.1;
    fetti.tiltSin = Math.sin(fetti.tiltAngle);
    fetti.tiltCos = Math.cos(fetti.tiltAngle);
    fetti.random = Math.random() + 2;
    fetti.wobbleX = fetti.x + 10 * fetti.scalar * Math.cos(fetti.wobble);
    fetti.wobbleY = fetti.y + 10 * fetti.scalar * Math.sin(fetti.wobble);

    var x1 = fetti.x + fetti.random * fetti.tiltCos;
    var y1 = fetti.y + fetti.random * fetti.tiltSin;
    var x2 = fetti.wobbleX + fetti.random * fetti.tiltCos;
    var y2 = fetti.wobbleY + fetti.random * fetti.tiltSin;

    context.beginPath();

    const svgMap = {
      fuel_coupon: fuelSvgMap,
      baskin_robbins: baskinRobbinsSvgMap,
      hob_fuel_coupon: fuelSvgMap,
      total_info_fuel_coupon: fuelSvgMap,
    };
    const { type, items } = (svgMap[fetti.category] ?? campingSvgMap)[fetti.shape];

    if (type === 'coin') {
      items.forEach((item) => {
        context.fillStyle = item.color;
        coinPath(
          context,
          fetti.x + fetti.wobble,
          fetti.y + fetti.wobble,
          (Math.PI / 10) * fetti.wobble,
          item.path,
          fetti.particleSize
        );
      });
    }

    if (type === 'particle') {
      items.forEach((item) => {
        context.fillStyle = item.color;
        path(
          context,
          fetti.x + fetti.wobble,
          fetti.y + fetti.wobble,
          Math.abs(x2 - x1) * 0.1,
          Math.abs(y2 - y1) * 0.1,
          (Math.PI / 10) * fetti.wobble,
          item.path,
          fetti.particleSize
        );
      });
    }
    context.closePath();

    return fetti.tick < fetti.totalTicks;
  }

  function animate(canvas, fettis, resizer, size, done) {
    var animatingFettis = fettis.slice();
    var context = canvas.getContext('2d');

    var animationFrame;
    var destroy;

    var prom = promise(function (resolve) {
      function onDone() {
        animationFrame = destroy = null;

        context.clearRect(0, 0, size.width, size.height);

        done();
        resolve();
      }

      function update() {
        if (isWorker && !(size.width === workerSize.width && size.height === workerSize.height)) {
          size.width = canvas.width = workerSize.width;
          size.height = canvas.height = workerSize.height;
        }

        if (!size.width && !size.height) {
          resizer(canvas);
          size.width = canvas.width;
          size.height = canvas.height;
        }

        context.clearRect(0, 0, size.width, size.height);

        animatingFettis = animatingFettis.filter(function (fetti) {
          return updateFetti(context, fetti);
        });

        if (animatingFettis.length) {
          animationFrame = raf.frame(update);
        } else {
          onDone();
        }
      }

      animationFrame = raf.frame(update);
      destroy = onDone;
    });

    return {
      addFettis: function (fettis) {
        animatingFettis = animatingFettis.concat(fettis);

        return prom;
      },
      canvas: canvas,
      promise: prom,
      reset: function () {
        if (animationFrame) {
          raf.cancel(animationFrame);
        }

        if (destroy) {
          destroy();
        }
      },
    };
  }

  function confettiCannon(canvas, globalOpts) {
    var isLibCanvas = !canvas;
    var allowResize = !!prop(globalOpts || {}, 'resize');
    var globalDisableForReducedMotion = prop(globalOpts, 'disableForReducedMotion', Boolean);
    var shouldUseWorker = canUseWorker && !!prop(globalOpts || {}, 'useWorker');
    var worker = shouldUseWorker ? getWorker() : null;
    var resizer = isLibCanvas ? setCanvasWindowSize : setCanvasRectSize;
    var initialized = canvas && worker ? !!canvas.__confetti_initialized : false;
    var preferLessMotion = typeof matchMedia === 'function' && matchMedia('(prefers-reduced-motion)').matches;
    var animationObj;

    function fireLocal(options, size, done) {
      var particleCount = prop(options, 'particleCount', onlyPositiveInt);
      var angle = prop(options, 'angle', Number);
      var spread = prop(options, 'spread', Number);
      var startVelocity = prop(options, 'startVelocity', Number);
      var decay = prop(options, 'decay', Number);
      var gravity = prop(options, 'gravity', Number);
      var drift = prop(options, 'drift', Number);
      var colors = prop(options, 'colors', colorsToRgb);
      var ticks = prop(options, 'ticks', Number);
      var shapes = prop(options, 'shapes');
      var scalar = prop(options, 'scalar');
      var particleSize = prop(options, 'particleSize');
      var category = prop(options, 'category');
      var origin = getOrigin(options);

      var temp = particleCount;
      var fettis = [];

      var startX = canvas.width * origin.x;
      var startY = canvas.height * origin.y;

      while (temp--) {
        fettis.push(
          randomPhysics({
            x: startX,
            y: startY,
            angle: angle,
            spread: spread,
            startVelocity: startVelocity,
            color: colors[temp % colors.length],
            shape: shapes[randomInt(0, shapes.length)],
            ticks: ticks,
            decay: decay,
            gravity: gravity,
            drift: drift,
            scalar: scalar,
            particleSize: particleSize,
            category: category,
          })
        );
      }

      // if we have a previous canvas already animating,
      // add to it
      if (animationObj) {
        return animationObj.addFettis(fettis);
      }

      animationObj = animate(canvas, fettis, resizer, size, done);

      return animationObj.promise;
    }

    function fire(options) {
      var disableForReducedMotion = globalDisableForReducedMotion || prop(options, 'disableForReducedMotion', Boolean);
      var zIndex = prop(options, 'zIndex', Number);

      if (disableForReducedMotion && preferLessMotion) {
        return promise(function (resolve) {
          resolve();
        });
      }

      if (isLibCanvas && animationObj) {
        // use existing canvas from in-progress animation
        canvas = animationObj.canvas;
      } else if (isLibCanvas && !canvas) {
        // create and initialize a new canvas
        canvas = getCanvas(zIndex);
        document.body.appendChild(canvas);
      }

      if (allowResize && !initialized) {
        // initialize the size of a user-supplied canvas
        resizer(canvas);
      }

      var size = {
        width: canvas.width,
        height: canvas.height,
      };

      if (worker && !initialized) {
        worker.init(canvas);
      }

      initialized = true;

      if (worker) {
        canvas.__confetti_initialized = true;
      }

      function onResize() {
        if (worker) {
          // TODO this really shouldn't be immediate, because it is expensive
          var obj = {
            getBoundingClientRect: function () {
              if (!isLibCanvas) {
                return canvas.getBoundingClientRect();
              }
            },
          };

          resizer(obj);

          worker.postMessage({
            resize: {
              width: obj.width,
              height: obj.height,
            },
          });
          return;
        }

        // don't actually query the size here, since this
        // can execute frequently and rapidly
        size.width = size.height = null;
      }

      function done() {
        animationObj = null;

        if (allowResize) {
          global.removeEventListener('resize', onResize);
        }

        if (isLibCanvas && canvas) {
          document.body.removeChild(canvas);
          canvas = null;
          initialized = false;
        }
      }

      if (allowResize) {
        global.addEventListener('resize', onResize, false);
      }

      if (worker) {
        return worker.fire(options, size, done);
      }

      return fireLocal(options, size, done);
    }

    fire.reset = function () {
      if (worker) {
        worker.reset();
      }

      if (animationObj) {
        animationObj.reset();
      }
    };

    return fire;
  }

  module.exports = confettiCannon(null, { useWorker: false, resize: true });
  module.exports.create = confettiCannon;
})(
  (function () {
    if (typeof window !== 'undefined') {
      return window;
    }

    if (typeof self !== 'undefined') {
      return self;
    }

    return this || {};
  })(),
  module,
  false
);
