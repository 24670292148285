import React, { ButtonHTMLAttributes, DetailedHTMLProps, ReactChild, useRef } from 'react';

import classNames from 'classnames';
import { Portal } from 'react-portal';

import Button, { Props as ButtonProps } from 'design-system/Button/Button';
import Typography from 'design-system/Typography/Typography';

import { ReactComponent as IcClose } from '../../design-system/Icon/close.svg';
import { useMeasurePolyfill } from '../../hooks/useMeasurePolyfill';

import styles from './BottomSheetDialog.module.scss';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  enableCloseButton?: boolean;
  enablePopupAnimation?: boolean;
  enableCloseOnOutsideClick?: boolean;
  dimmerClass?: string;
  wrapperClass?: string;
  contentClass?: string;
  height?: number;
  hasDimmer?: boolean;
}

type BottomSheetDialogComponent = {
  Title: React.FC;
  Content: React.FC<{ className?: string }>;
  Button: React.FC<ButtonProps & React.HTMLAttributes<HTMLButtonElement>>;
  Caption: React.FC;
};
// DESC usage
//  height를 bounds로 계산하기 때문에
//  margin 안 먹습니다
const BottomSheetDialog: React.FC<Props> & BottomSheetDialogComponent = ({
  children,
  isOpen,
  onClose,
  enableCloseButton = false,
  enablePopupAnimation = true,
  contentClass,
  wrapperClass,
  height = null,
  hasDimmer = false,
  dimmerClass,
}) => {
  const [ref, bounds] = useMeasurePolyfill();
  const scrollEnableTargetRef = useRef<HTMLDivElement>(null);

  const renderContent = (children: ReactChild) => {
    if (hasDimmer) {
      return <div className={classNames(styles.dimmer, { [styles.isOpen]: isOpen }, dimmerClass)}>{children}</div>;
    } else {
      return <>{children}</>;
    }
  };
  return renderContent(
    <Portal>
      <div
        className={classNames(styles.wrapper, !enablePopupAnimation && styles.noTransition, wrapperClass)}
        style={{ height: isOpen ? height || bounds.height : 0, opacity: isOpen ? 1 : 0 }}
        ref={scrollEnableTargetRef}
      >
        <div ref={ref} className={classNames(contentClass)}>
          {children}
        </div>
        {enableCloseButton && (
          <span className={styles.closeIconWrap} onClick={onClose}>
            <IcClose className={styles.closeIcon} />
          </span>
        )}
      </div>
    </Portal>
  );
};

const Title: React.FC<{ className?: string }> = ({ children, className }) => (
  <Typography variant={'Subtitle_2'} className={className}>
    {children}
  </Typography>
);

// TODO 린트가 싫어해서 주석처리
//  쓸 때 주석 풀고 꺼내 쓰면 됩니다
// export const BottomSheetDialogSubtitle: React.FC<{ className?: string }> = ({ children }) => (
//   <Typography variant={'Subtitle_16'}>{children}</Typography>
// );
const Content: React.FC<{ className?: string }> = ({ children, className }) => (
  <Typography variant={'Body_2'} className={classNames(styles.content, className)}>
    {children}
  </Typography>
);

const Caption: React.FC<{ className?: string }> = ({ children, className }) => (
  <Typography variant={'Caption'} className={classNames(styles.caption, className)}>
    {children}
  </Typography>
);

const BottomSheetDialogButton: React.FC<
  ButtonProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ ...otherProps }) => <Button className={styles.button} {...otherProps} variant={'primaryBrandcolor'} />;

BottomSheetDialog.Title = Title;
BottomSheetDialog.Content = Content;
BottomSheetDialog.Button = BottomSheetDialogButton;
BottomSheetDialog.Caption = Caption;

export default BottomSheetDialog;
