import { useMemo } from 'react';

import { map } from 'lodash';
import { config, useTransition } from 'react-spring';

const COLUMNS = 3;
const ROWS = 3;

const useCardListTransition = (width: number, height: number) => {
  const cardWidth = width / COLUMNS;
  const cardHeight = height / ROWS;
  const items = useMemo(() => {
    const cards = new Array(9).fill(undefined);

    return cards.map((coupon, i) => {
      const x = cardWidth * (i % COLUMNS) + cardWidth * 0.04 * (i % COLUMNS);
      const y = cardHeight * Math.floor(i / ROWS) + cardHeight * 0.03 * Math.floor(i / ROWS);
      return { x, y, width: cardWidth * 0.92, height: cardHeight * 0.92, coupon };
    });
  }, [cardWidth, cardHeight]);

  return useTransition(items, {
    from: ({ y, height }) => ({ x: 0, y, width: 0, height, opacity: 0 }),
    enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
    config: config.slow,
    trail: 200,
    delay: 400,
  });
};

export default useCardListTransition;
