import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { find, includes, map } from 'lodash';
import { useMutation } from 'react-query';
import { a } from 'react-spring';

import { Category, Coupon } from 'types';

import pxios from '../../apis/pxios';
import { useMeasurePolyfill } from '../../hooks/useMeasurePolyfill';
import { useToast } from '../../hooks/useToast';
import { EventStatus } from '../../utils/getEventStatus';
import Card from '../Card/Card';

import useCardListTransition from './useCardListTransition';

import styles from './CardList.module.scss';

interface Props {
  carHashId: string | null;
  coupons?: Coupon[];
  flipCardIndex: number;
  setFlipCardIndex: Dispatch<SetStateAction<number>>;
  eventStatus: EventStatus;
  openPhoneNumberInputPopup: () => void;
  isShareButtonClick: boolean;
  category?: Category;
}

// 상태변화에 따라 리랜더링이 되지 않게 하기위해 react 밖에 선언
let disableCardClick = false;
const CardList: React.FC<Props> = ({
  carHashId,
  coupons,
  flipCardIndex,
  setFlipCardIndex,
  eventStatus,
  openPhoneNumberInputPopup,
  isShareButtonClick,
  category,
}) => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>();

  const getRandomGlowIndex = () => Math.floor(Math.random() * 9);
  const [glowingCardIndex, setGLowingCardIndex] = useState<number>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toast = useToast();

  const [ref, { width, height }] = useMeasurePolyfill();

  let intervalId: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (!selectedCardIndex) {
      intervalId = setInterval(() => {
        setGLowingCardIndex(getRandomGlowIndex());
      }, 1500);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        setGLowingCardIndex(undefined);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedCardIndex]);

  const { mutateAsync, data } = useMutation<AxiosResponse<Coupon>, AxiosError, number>('cardFlip', (choiceNumber) => {
    return pxios.post('/gift_coupon_event/select_card/', {
      car: carHashId ?? undefined,
      choice_number: choiceNumber,
    });
  });

  const onClickCard = async (index: number) => {
    // 더블 click 방지
    if (disableCardClick) {
      return;
    }

    const cardNumber = index + 1;

    // 이미 선택한 카드
    if (includes(map(coupons, 'choice_number'), cardNumber)) {
      return;
    }

    if (eventStatus === 'shareRequired') {
      if (isShareButtonClick) {
        toast.fail('친구에게 공유되지 않았어요.');
        return;
      }
      toast.default('친구에게 공유해야 뒤집을 수 있어요!');
      return;
    }

    if (eventStatus === 'done') {
      return;
    }

    disableCardClick = true;
    setTimeout(() => {
      disableCardClick = false;
    }, 3000);

    const response = await mutateAsync(index + 1);

    const image = new Image();
    image.src = response.data.image_url;
    image.onload = () => {
      setSelectedCardIndex(index);
    };
  };

  const transitions = useCardListTransition(width, height);
  return (
    <>
      <div ref={wrapperRef}>
        <div ref={ref} className={styles.cardList}>
          {transitions((style, item, t, i) => {
            return (
              <a.div style={style} className={classNames(styles.cardWrapper)}>
                <Card
                  index={i}
                  flipCardIndex={flipCardIndex}
                  setFlipCardIndex={setFlipCardIndex}
                  glowingCardIndex={glowingCardIndex}
                  selectedCardIndex={selectedCardIndex}
                  onClickCard={onClickCard}
                  coupon={find(coupons, (coupon) => coupon.choice_number === i + 1)}
                  openPhoneNumberInputPopup={openPhoneNumberInputPopup}
                  eventStatus={eventStatus}
                  selectedCoupon={data?.data.choice_number === i + 1 ? data?.data : undefined}
                  category={category}
                />
              </a.div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CardList;
