import React from 'react';

import { Coupon } from 'types';

import Typography from '../../../../design-system/Typography/Typography';
import { ReactComponent as Lock } from '../../../../images/lock.svg';
import { ReactComponent as Logo } from '../../../../images/logo.svg';
import { EventStatus } from '../../../../utils/getEventStatus';

import styles from './MiniCard.module.scss';

interface Props {
  coupon?: Coupon;
  eventStatus: EventStatus;
}
const MiniCard: React.FC<Props> = ({ coupon, eventStatus }) => {
  return (
    <>
      <div className={styles.front}>
        {eventStatus === 'shareRequired' || eventStatus === 'done' ? <Lock /> : <Logo className={styles.logo} />}
      </div>
      <div
        className={styles.back}
        style={{ background: `url(${coupon?.image_url}) 50% 25%/70% no-repeat white`, backgroundSize: `87.5%` }}
      >
        <Typography variant="Subtitle_4" className={styles.name}>
          {coupon?.name}
        </Typography>
      </div>
    </>
  );
};

export default MiniCard;
