import React from 'react';

import classNames from 'classnames';

import { Coupon } from 'types';

import Typography from '../../../../design-system/Typography/Typography';

import styles from './FuelCardBackSide.module.scss';

interface Props {
  coupon: Coupon;
}

const FuelCardBackSide: React.FC<Props> = ({ coupon }) => {
  return (
    <>
      <div className={classNames(styles.content)}>
        <Typography variant={'Headline_2'}>
          {coupon.name} 주유권을 <br />
          받았어요!
        </Typography>
      </div>
    </>
  );
};

export default FuelCardBackSide;
