import React from 'react';

import classNames from 'classnames';

import styles from './Layout.module.scss';

interface Props {
  className?: string;
}

const Layout: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames(styles.layout, className)}>{children}</div>;
};

export default Layout;
