import React from 'react';

import classNames from 'classnames';
import josa from 'josa-js';

import { Coupon } from 'types';

import Typography from '../../../../design-system/Typography/Typography';

import styles from './CardBackSide.module.scss';

interface Props {
  coupon: Coupon;
}

const CardBackSide: React.FC<Props> = ({ coupon }) => {
  return (
    <>
      <div className={classNames(styles.content)}>
        <Typography variant={'Headline_2'}>
          {josa.r(coupon.name, '을/를')} <br />
          받았어요!
        </Typography>
      </div>
    </>
  );
};

export default CardBackSide;
