import React from 'react';

import classNames from 'classnames';

import { ReactComponent as Back } from 'images/back.svg';
import { ReactComponent as Close } from 'images/close.svg';

import closeWebview from '../../utils/closeWebview';

import styles from './CardPageHeader.module.scss';

interface Props {
  isFlipped: boolean;
}
const CardPageHeader: React.FC<Props> = ({ isFlipped }) => {
  const onClick = () => {
    history.back();
    setTimeout(() => {
      location.reload();
    }, 100);
  };

  const onClickClose = () => {
    closeWebview();
  };

  return (
    <div className={styles.header}>
      {isFlipped ? (
        <Back className={classNames(styles.backButton)} onClick={onClick} />
      ) : (
        <Close className={classNames(styles.closeButton)} onClick={onClickClose} />
      )}
    </div>
  );
};

export default CardPageHeader;
