import React, { ChangeEvent, useRef, useState } from 'react';

import { trim } from 'lodash';
import { DateTime } from 'luxon';
import { isIOS } from 'react-device-detect';
import { useMutation } from 'react-query';

import pxios from '../../../apis/pxios';
import BottomSheetDialog from '../../../design-system/BottomSheetDialog/BottomSheetDialog';
import InputField from '../../../design-system/Form/InputField/InputField';
import Typography from '../../../design-system/Typography/Typography';
import { useToast } from '../../../hooks/useToast';
import { Promotion } from '../../../types';
import hapticWebview from '../../../utils/hapticWebview';
import { toFormattedInputPhoneNumber } from '../../../utils/toFormattedInputPhoneNumber';

import PhoneNumberCheckModal from './PhoneNumberCheckModal/PhoneNumberCheckModal';

import styles from './PhoneNumberInputPopup.module.scss';

interface Props {
  openBottomSheetDialog: boolean;
  onClose: () => void;
  dimmer: boolean;
  car: string | null;
  event: Promotion;
}
const PhoneNumberInputPopup: React.FC<Props> = ({ openBottomSheetDialog, car, onClose, dimmer, event }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isVisiblePhoneNumberCheckModal, setVisiblePhoneNumberCheckModal] = useState(false);
  const toast = useToast();
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);

  const { mutate } = useMutation(
    async (data: { phone_number: string; car?: string }) => await pxios.post(`/gift_coupon_event/target/`, data),
    {
      onSuccess: () => {
        onClose();
        location.reload();
      },
    }
  );

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(toFormattedInputPhoneNumber(e.currentTarget.value));
  };

  const onConfirm = () => {
    // ios에서 onClick 사용시
    // keyboard가 올라와 있을 경우 확인 버튼이 한번에 안눌리는 이슈가 있어
    // onTouchStart 이벤트를 사용한다.
    if (phoneNumberInputRef.current) {
      phoneNumberInputRef.current.blur();
    }

    if (!trim(phoneNumber)) {
      toast.default('휴대폰 번호를 입력해주세요. ');
      hapticWebview('failure');
      return;
    }
    hapticWebview('interact');
    setVisiblePhoneNumberCheckModal(true);
  };

  const handleModifyPhoneNumber = () => {
    if (phoneNumberInputRef.current) {
      phoneNumberInputRef.current.focus();
    }
  };

  const onClosePhoneNumberCheckModal = () => {
    setVisiblePhoneNumberCheckModal(false);
  };

  const handlePhoneNumberCheckModalConfirm = () => {
    mutate({ car: car ?? undefined, phone_number: phoneNumber.replace(/\D/g, '') });
  };

  const onBlur = () => {
    // ios13 버전에서 input focus시 keyboard가 올라올 때 view 올라가는 현상에 대한 대응
    // https://prndcompany.atlassian.net/browse/HDW-1666?focusedCommentId=10648&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-10648
    window.scrollTo(0, 0);
  };

  const onClickDelete = () => {
    setPhoneNumber('');
  };
  return (
    <>
      <BottomSheetDialog hasDimmer={dimmer} isOpen={openBottomSheetDialog} onClose={onClose}>
        <BottomSheetDialog.Content>
          <Typography variant="Subtitle_1">휴대폰 번호 입력</Typography>
          <div className={styles.inputWrapper}>
            <InputField
              inputMode="tel"
              placeholder="휴대폰 번호"
              size="big"
              onChange={onChangePhoneNumber}
              value={phoneNumber}
              ref={phoneNumberInputRef}
              onFocus={() => setTimeout(() => window.scrollTo({ top: window.innerHeight }), 200)}
              onBlur={onBlur}
              inputClassName={styles.phoneNumberInput}
              onClickDelete={onClickDelete}
            />
          </div>
          <div className={styles.info}>
            {event.promotion_end_at
              ? `📮 주유권은 ${DateTime.fromFormat(event.promotion_end_at, 'yyyy-MM-dd HH:mm:ss')
                  .plus({ days: 7 })
                  .toFormat('M월 d일')}까지 문자로 안내드릴 예정입니다.`
              : ''}
          </div>
          <BottomSheetDialog.Button
            className={styles.confirmButton}
            onClick={isIOS ? undefined : onConfirm}
            onTouchStart={isIOS ? onConfirm : undefined}
            text="확인"
          />
        </BottomSheetDialog.Content>
      </BottomSheetDialog>
      <PhoneNumberCheckModal
        isVisible={isVisiblePhoneNumberCheckModal}
        onClose={onClosePhoneNumberCheckModal}
        phoneNumber={phoneNumber}
        handleModifyPhoneNumber={handleModifyPhoneNumber}
        handlePhoneNumberCheckModalConfirm={handlePhoneNumberCheckModalConfirm}
      />
    </>
  );
};

export default PhoneNumberInputPopup;
