import { size } from 'lodash';

import { Coupon } from 'types';

export type EventStatus = 'init' | 'shareRequired' | 'shared' | 'done';
export const getEventStatus = (coupons?: Coupon[], isShared?: boolean): EventStatus => {
  if (size(coupons) === 0) {
    return 'init'; // 한번도 카드를 뒤집지 않은 상태
  } else if (size(coupons) === 1 && !isShared) {
    return 'shareRequired'; // 한번 카드를 뒤집고 공유 하지 않은 상태
  } else if (size(coupons) === 1 && isShared) {
    return 'shared'; // 한번 카드를 뒤집고 공유한 상태
  } else {
    return 'done'; // 2번 카드를 뒤집은 상태
  }
};
