import { Category } from 'types';

import confetti from './confetti';

const defaults = {
  origin: { y: 0.7 },
};

type Confetti = {
  spread?: number;
  startVelocity?: number;
  decay?: number;
  scalar?: number;
  shapes?: string[];
  particleSize?: 'normal' | 'small' | 'big';
  particleCount?: number;
  category?: Category;
};
const fireConfetti = (opts: Confetti, onEndFire?: () => void) => {
  confetti(Object.assign({}, defaults, opts));
  setTimeout(() => {
    confetti.reset();
    if (onEndFire) {
      onEndFire();
    }
  }, 3000);
};

export const fireCardAwardConfetti = (category: Category, onEndFire?: () => void) => {
  fireConfetti({
    particleCount: 15,
    spread: 26,
    startVelocity: 55,
    shapes: ['coin1', 'coin2', 'coin3', 'particle1', 'particle2', 'particle3', 'particle4', 'particle5', 'particle6'],
    particleSize: 'big',
    category,
  });
  fireConfetti({
    particleCount: 15,
    spread: 60,
    shapes: ['particle1', 'particle2', 'particle3', 'particle4', 'particle5', 'particle6'],
    particleSize: 'big',
    category,
  });
  fireConfetti(
    {
      particleCount: 15,
      spread: 50,
      startVelocity: 25,
      shapes: ['particle1', 'particle2', 'particle3', 'particle4', 'particle5', 'particle6'],
      particleSize: 'big',
      category,
    },
    onEndFire
  );
};
