import React from 'react';

import classNames from 'classnames';
import { get } from 'lodash';
import { animated, useTransition } from 'react-spring';

import { ReactComponent as ConfirmFailIcon } from 'design-system/Icon/confirm_fail.svg';
import { ReactComponent as CheckIcon } from 'design-system/Icon/confirm_pass.svg';
import { ReactComponent as InfoInformationIcon } from 'design-system/Icon/info_information.svg';
import { ToastVariant } from 'store/toastSlice';

import { useAppSelector } from '../../hooks/useAppSelector';

import styles from './Toast.module.scss';

interface Props {}
const Toast: React.FC<Props> = () => {
  const toasts = useAppSelector((state) => state.toast.toasts);

  const renderIcon = (variant: ToastVariant) => {
    switch (variant) {
      case 'default': {
        return <InfoInformationIcon className={styles.icon} />;
      }
      case 'success': {
        return <CheckIcon className={styles.icon} />;
      }
      case 'fail': {
        return <ConfirmFailIcon className={styles.icon} />;
      }
    }
  };

  const transitions = useTransition(toasts, {
    from: { opacity: 0, y: -3.2 },
    enter: { opacity: 1, y: 3.2 },
    leave: { opacity: 0, y: -3.2 },
  });

  return (
    <>
      {transitions(({ opacity, y }, toast, t, index) => {
        const variant = get(toast, 'variant');
        const message = get(toast, 'message');
        return (
          <animated.div
            style={{ opacity, bottom: y.to((v) => `${v * (index + 1)}rem`) }}
            className={classNames(styles.toast, styles[variant])}
          >
            {renderIcon(variant)}
            {message}
          </animated.div>
        );
      })}
    </>
  );
};

export default Toast;
