import React, { CSSProperties, useRef } from 'react';

import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { Portal } from 'react-portal';
import { animated, config, useTransition } from 'react-spring';

import styles from './Modal.module.scss';

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

const Modal: React.FC<ModalProps> = ({ isVisible, children, onClose, disabled = false, className, style }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const transitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
    unique: true,
  });

  return (
    <Portal>
      {transitions(
        (props, item) =>
          item && (
            <animated.div className={styles.dimmer} style={props}>
              <OutsideClickHandler onOutsideClick={onClose} disabled={disabled}>
                <div className={classNames(styles.modal, className)} style={style} ref={containerRef}>
                  {children}
                </div>
              </OutsideClickHandler>
            </animated.div>
          )
      )}
    </Portal>
  );
};

export default Modal;
