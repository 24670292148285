import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import Cookie from 'js-cookie';
import { get } from 'lodash';

import { store } from '../store';
import { createToast, ToastVariant } from '../store/toastSlice';

const baseURL = Cookie.get('Base-Url');

// baseURL: baseURL ? `${new URL(baseURL).origin}/` : process.env.REACT_APP_API,
export const requester = axios.create({
  baseURL: baseURL ? `${new URL(baseURL).origin}/v2/customers/` : process.env.REACT_APP_HOST,
});

export const setAuthorizedRequesterToken = (token: string) => {
  requester.defaults.headers['Authorization'] = `JWT ${token}`;
};

type ServerToastType = 'success' | 'error' | 'default';

const toastTypeMap: Record<ServerToastType, ToastVariant> = {
  success: 'success',
  error: 'fail',
  default: 'default',
};
const treatPxiosResponse = (promise: AxiosPromise) => {
  return promise
    .then((response) => response)
    .catch((err: any) => {
      const message = get(err, ['response', 'data', 'toast', 'message']);
      const type: ServerToastType | undefined = get(err, ['response', 'data', 'toast', 'type']);
      if (message) {
        // Todo thunkAction으로 변경(any 타입 없애기)
        store.dispatch<any>(createToast({ message, variant: toastTypeMap[type || 'default'] }));
      } else {
        store.dispatch<any>(createToast({ message: `Error: ${err.response.status}`, variant: 'fail' }));
      }
      throw new Error(err.response.data.toast?.message ?? err);
    });
};

class Pxios {
  get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.get(url, config));
  }
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.put(url, data, config));
  }
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.post(url, data, config));
  }
  delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.delete(url, config));
  }
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.patch(url, data, config));
  }
}

const pxios = new Pxios();
export default pxios;
