export interface KaKaoShareData {
  link_url: string;
  image_url: string;
  title: string;
  description: string;
  button_text: string;
  image_width: number;
  image_height: number;
  server_callback_args: ServerCallbackArgs;
}

export interface ServerCallbackArgs {
  user_hash_id: string;
  environment: string;
  event: string;
}

export default function shareWebview(shareData: KaKaoShareData) {
  if (window.Android && window.Android.shareKakaoLink) {
    window.Android.shareKakaoLink(JSON.stringify(shareData));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.shareKakaoLink) {
    window.webkit.messageHandlers.shareKakaoLink.postMessage(shareData);
  }
}
