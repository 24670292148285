import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Portal } from 'react-portal';
import { focusManager } from 'react-query';

import styles from './Timer.module.scss';

interface Props {
  endTime: string;
  className?: string;
  isActiveEventEndDimmer?: boolean; // true이면 event가 종료될 때 dimmer를 출력한다.
}
const Timer: React.FC<Props> = ({ endTime, className, isActiveEventEndDimmer = true }) => {
  const endTimeDateTime = endTime ? DateTime.fromFormat(endTime, 'yyyy-MM-dd HH:mm:ss').diffNow() : DateTime.local();
  const [remainedTime, setRemainedTime] = useState(endTimeDateTime.toMillis());

  const isWindowFocused = focusManager.isFocused();

  useEffect(() => {
    if (isWindowFocused) {
      setRemainedTime(endTimeDateTime.toMillis());
    }
  }, [isWindowFocused]);

  const twoDigitFormatting = (n: number) => {
    if (n >= 0 && n <= 9) return `0${n}`;
    else {
      return n;
    }
  };
  const toTimeFormat = (s: number) => {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    return s + ' : ' + twoDigitFormatting(secs) + ' : ' + twoDigitFormatting(Math.floor(ms / 10));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainedTime((prev) => {
        return prev - 10;
      });
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className={classNames(styles.remainedTimeWrapper, className)}>
        {remainedTime > 0 && <span className={styles.remainedTime}>{toTimeFormat(remainedTime)}&nbsp;남음</span>}
      </div>
      {remainedTime < 0 && isActiveEventEndDimmer && (
        <Portal>
          <div className={styles.eventEndDimmer} />
        </Portal>
      )}
    </>
  );
};

export default Timer;
