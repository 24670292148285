import React, { Dispatch, FC, SetStateAction, TransitionEvent, useRef, useState } from 'react';

import classNames from 'classnames';

import { Category, Coupon } from 'types';

import { EventStatus } from '../../utils/getEventStatus';
import hapticWebview from '../../utils/hapticWebview';

import FuelClonedCard from './fuel/FuelClonedCard/FuelClonedCard';
import ClonedCard from './product/ClonedCard/ClonedCard';
import MiniCard from './product/MiniCard/MiniCard';

import styles from './Card.module.scss';

interface Props {
  onClickCard: (choiceNumber: number) => void;
  index: number;
  flipCardIndex: number;
  glowingCardIndex?: number;
  selectedCardIndex?: number;
  coupon?: Coupon;
  setFlipCardIndex: Dispatch<SetStateAction<number>>;
  openPhoneNumberInputPopup: () => void;
  eventStatus: EventStatus;
  selectedCoupon?: Coupon;
  category?: Category;
}

/**
 *  1. 카드 선택
 *  2. setSelectedCardNumber(선택된 카드 번호 CardList 상태에 저장)
 *  3. select_card API 호출
 *
 *
 *  state=award 당첨 후 새로고침해도 당첨 화면을 볼 수 있게하기 위한 상태로 route와 같은 역할
 *  state=award & gift의 값이 존재하면 gift의 마지막 당첨화면을 보여준다.
 *
 *  당첨화면을 보여주기 위한 데이터
 *  - gift
 *  - description
 * **/
const Card: FC<Props> = ({
  onClickCard,
  index,
  flipCardIndex,
  glowingCardIndex,
  selectedCardIndex,
  coupon,
  setFlipCardIndex,
  openPhoneNumberInputPopup,
  eventStatus,
  selectedCoupon, // 카드 선택 API를 호출 했을 때 반환되는 coupon
  category,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [miniCardClientRect, setMiniCardClientRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [shakingEnd, setShakingEnd] = useState(false);

  // 1. 카드 선택 -> API 호출 -> setSelectedCardNumber(흔들리는 애니메이션 시작)
  const onClick = (index: number) => {
    hapticWebview('interact');
    onClickCard(index);
    if (cardRef.current) {
      const { x, y, width, height } = cardRef.current.getBoundingClientRect() || {};
      setMiniCardClientRect({ x, y, width, height });
    }
  };

  const onShakingAnimationEnd = () => {
    //shaking 후 setTime으로 flip해야 정상 작동, 이유 모르겠음
    setTimeout(() => {
      hapticWebview('interact');
      setShakingEnd(true);
    }, 100);
  };

  const onFlipTransitionEnd = (e: TransitionEvent, index: number) => {
    if (e.propertyName === 'transform') {
      setFlipCardIndex(index);
    }
  };

  const renderCard = () => {
    if (flipCardIndex === index && selectedCoupon && category) {
      return ['fuel_coupon', 'hob_fuel_coupon', 'total_info_fuel_coupon'].includes(category) ? (
        <FuelClonedCard
          isCardFlip={flipCardIndex === index}
          miniCardClientRect={miniCardClientRect}
          coupon={selectedCoupon}
          openPhoneNumberInputPopup={openPhoneNumberInputPopup}
          category={category}
        />
      ) : (
        <ClonedCard
          isCardFlip={flipCardIndex === index}
          miniCardClientRect={miniCardClientRect}
          coupon={selectedCoupon}
          openPhoneNumberInputPopup={openPhoneNumberInputPopup}
          category={category}
        />
      );
    } else {
      return (
        <div
          className={classNames(
            styles.card,
            classNames({
              [styles.shaking]: selectedCardIndex === index,
              [styles.glow]: !coupon && glowingCardIndex === index,
              [styles.flip]: shakingEnd,
              [styles.flipped]: coupon && selectedCardIndex !== index,
            })
          )}
          onClick={() => onClick(index)}
          onAnimationEnd={onShakingAnimationEnd}
          onTransitionEnd={(e) => onFlipTransitionEnd(e, index)}
          ref={cardRef}
        >
          <MiniCard coupon={coupon || selectedCoupon} eventStatus={eventStatus} />
        </div>
      );
    }
  };
  return renderCard();
};

export default Card;
