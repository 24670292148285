import React, { memo, useState } from 'react';

import { ReactComponent as CheckIcon } from 'design-system/Icon/confirm_pass.svg';

import Button from '../../../../design-system/Button/Button';
import Checkbox from '../../../../design-system/Form/Selector/Checkbox/Checkbox';
import Typography from '../../../../design-system/Typography/Typography';
import { useToast } from '../../../../hooks/useToast';
import hapticWebview from '../../../../utils/hapticWebview';
import { toFormattedInputPhoneNumber } from '../../../../utils/toFormattedInputPhoneNumber';
import Modal from '../../../Modal/Modal';

import styles from './PhoneNumberCheckModal.module.scss';

interface Props {
  isVisible: boolean;
  onClose: (e?: React.MouseEvent<HTMLElement>) => void;
  phoneNumber: string;
  handleModifyPhoneNumber: () => void;
  handlePhoneNumberCheckModalConfirm: () => void;
}
const PhoneNumberCheckModal: React.FC<Props> = memo(
  ({ isVisible, onClose, phoneNumber, handleModifyPhoneNumber, handlePhoneNumberCheckModalConfirm }) => {
    const [isCheckPrivacy, setCheckPrivacy] = useState(false);
    const toast = useToast();
    const onClickModifyNumber = () => {
      hapticWebview('interact');
      handleModifyPhoneNumber();
      onClose();
    };

    const onClickConfirm = () => {
      if (!isCheckPrivacy) {
        toast.default('개인정보 활용에 동의해주세요.');
        hapticWebview('failure');
        return;
      }
      hapticWebview('interact');
      handlePhoneNumberCheckModalConfirm();
      onClose();
    };
    return (
      <Modal isVisible={isVisible} onClose={onClose} className={styles.modal} disabled>
        <div className={styles.body}>
          <Typography variant="Headline_3">🙌&nbsp;&nbsp;번호 확인</Typography>
          <Typography variant="Body_1" className={styles.description}>
            휴대폰 번호는 주유권 발송에 사용되며 <br />
            이벤트 종료 후 일괄 삭제됩니다.
          </Typography>
          <div className={styles.phoneNumberWrapper}>
            <Typography variant="Headline_3">{toFormattedInputPhoneNumber(phoneNumber)}</Typography>
          </div>
          <Checkbox
            label="개인정보 활용에 동의합니다."
            className={styles.checkbox}
            checked={isCheckPrivacy}
            onChange={(e) => {
              const value = e.currentTarget.checked;
              setCheckPrivacy(value);
            }}
          />
        </div>
        <footer className={styles.footer}>
          <Button variant="textBrandcolor" text="휴대폰 번호 수정" onClick={onClickModifyNumber} />
          <Button
            variant="primaryBrandcolor"
            text={'완료'}
            className={styles.confirmButton}
            leftIcon={<CheckIcon className={styles.checkIcon} />}
            onClick={onClickConfirm}
          />
        </footer>
      </Modal>
    );
  }
);

export default PhoneNumberCheckModal;
