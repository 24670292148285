import React, { forwardRef, ReactNode } from 'react';

import classNames from 'classnames';

import { ReactComponent as DeleteIcon } from 'design-system/Icon/delete.svg';

import styles from './InputField.module.scss';

interface Props {
  // style
  size?: 'small' | 'big';
  value: string;
  warning?: boolean;
  rightAddon?: ReactNode;
  disabled?: boolean;
  // customize
  className?: string;
  inputClassName?: string;
  onClickDelete?: () => void;
}

// DESC
//  html <input> 의 size 속성은 안 먹습니다 (Omit 함)

const InputField: React.ForwardRefRenderFunction<HTMLInputElement, Props & React.HTMLAttributes<HTMLInputElement>> = (
  { className, size = 'small', warning = false, rightAddon, disabled, inputClassName, onClickDelete, ...otherProps },
  ref
) => {
  const combinedClassName = classNames(warning && styles.warning, disabled && styles.disabled, {
    [styles.rightAddon]: rightAddon,
  });

  return (
    <div className={classNames(styles.wrapper, combinedClassName, className)}>
      <input
        {...otherProps}
        className={classNames(styles.input, styles[size], inputClassName)}
        value={otherProps.value}
        ref={ref}
      />
      {rightAddon}
      {onClickDelete && otherProps.value.length !== 0 && (
        <DeleteIcon onTouchStart={onClickDelete} className={styles.deleteIcon} />
      )}
    </div>
  );
};

export default forwardRef(InputField);
