import React from 'react';

import { ClipLoader } from 'react-spinners';

import { brandcolor_primary } from '../../design-system/color-palette/color-palette';

import styles from './LoadingPage.module.scss';

const LoadingPage: React.FC = () => {
  return (
    <div className={styles.loadingPage}>
      <ClipLoader color={brandcolor_primary} />
    </div>
  );
};

export default LoadingPage;
