import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import Cookie from 'js-cookie';
import { useQuery } from 'react-query';
import { a, useSpring } from 'react-spring';

import { ReactComponent as KakaoShare } from 'images/kakao-share.svg';
import { Promotion } from 'types';

import pxios, { setAuthorizedRequesterToken } from '../../apis/pxios';
import totalInfoShareImg from '../../assets/img/total_info_share.png';
import CardList from '../../components/CardList/CardList';
import PhoneNumberInputPopup from '../../components/CardList/PhoneNumberInputPopup/PhoneNumberInputPopup';
import CardPageHeader from '../../components/CardPageHeader/CardPageHeader';
import Layout from '../../components/Layout/Layout';
import Timer from '../../components/Timer/Timer';
import Button from '../../design-system/Button/Button';
import { base_white, brandcolor_primary } from '../../design-system/color-palette/color-palette';
import Typography from '../../design-system/Typography/Typography';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { getEventStatus } from '../../utils/getEventStatus';
import hapticWebview from '../../utils/hapticWebview';
import setStatusBarWebview from '../../utils/setStatusBarWebview';
import shareWebview from '../../utils/shareWebview';
import LoadingPage from '../LoadingPage/LoadingPage';

import styles from './FlipCardPage.module.scss';

const FlipCardPage: React.FC = () => {
  const query = useURLSearchParams();
  const carHashId = query.get('car');
  const [flipCardIndex, setFlipCardIndex] = useState(-1);
  const [isShareButtonClick, setShareButtonClick] = useState(false);
  const [isVisiblePhoneNumberInputPopup, setVisiblePhoneNumberInputPopup] = useState(false);
  const [dimmer, setDimmer] = useState(false);
  const [isEventApiFetched, setEventApiFetched] = useState(false);

  const openPhoneNumberInputPopup = () => {
    if (!event?.is_phone_number_inputted) {
      setVisiblePhoneNumberInputPopup(true);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#10131a';
    setStatusBarWebview({ color: '#10131a', content_brightness: 'light' });
  }, []);

  const contentStyle = useSpring(
    flipCardIndex === -1
      ? {
          opacity: 1,
        }
      : {
          opacity: 0,
        }
  );

  const token = Cookie.get('csrftoken');

  if (token) {
    setAuthorizedRequesterToken(token);
  }

  /**
   * action=init 이 붙은 api로 이벤트 종료 여부를 판단
   * action=init이 없는 api는 이벤트 종료 여부에 따라 바로 막지 않는다.
   * **/
  const {
    data: event,
    isLoading,
    isError,
  } = useQuery({
    queryKey: '/gift_coupon_event/target/',
    queryFn: async () =>
      (
        await pxios.get<Promotion>('/gift_coupon_event/target/', {
          params: {
            car: carHashId,
            ...(isEventApiFetched ? {} : { action: 'init' }),
          },
        })
      ).data,
    onSuccess: () => setEventApiFetched(true),
  });

  useEffect(() => {
    // 전화번호를 입력하지 않았다면 전화번호 입력 팝업을 띄워준다.
    if (event && !event?.is_phone_number_inputted && event.coupons?.length !== 0) {
      setDimmer(true);
      setVisiblePhoneNumberInputPopup(true);
    }
  }, [event]);

  if (isLoading || !event) {
    return <LoadingPage />;
  }

  const renderContent = () => {
    if (!token || isError) {
      return (
        <div className={styles.noToken}>
          <Typography variant="Body_1" className={styles.info}>
            이벤트가 종료되었습니다.
          </Typography>
        </div>
      );
    }

    const eventStatus =
      event?.category === 'hob_fuel_coupon'
        ? event.coupons.length > 0
          ? 'done'
          : 'init'
        : getEventStatus(event.coupons, event.is_shared);

    const renderTitle = () => {
      switch (eventStatus) {
        case 'init':
          return (
            <div className={styles.selectCard}>
              카드를 1장
              <br /> 고르세요!
            </div>
          );
        case 'shareRequired':
          return (
            <>
              친구에게 알려주고 <br />
              한번 더 카드를 고르세요.
            </>
          );
        case 'shared':
          return (
            <>
              공유가 완료되었습니다! <br />
              한번 더 카드를 고르세요.
            </>
          );
        case 'done':
          return (
            <>
              친구가 주유권을 받을 수 있게 <br />
              공유해 주세요.
            </>
          );
      }
    };
    const isProduction = process.env.REACT_APP_ENV === 'production';

    const isVisibleShareButton = () => {
      // 당첨이 되었고 전화번호를 입력하지 않았다면 공유 버튼을 보여주지 않는다.
      return (eventStatus === 'shareRequired' || eventStatus === 'done') && event.is_phone_number_inputted;
    };

    const handleKaKaoShare = () => {
      if (event.user) {
        setShareButtonClick(true);
        hapticWebview('interact');
        shareWebview({
          link_url: event.kakao_share_link,
          image_url: new URL(totalInfoShareImg, window.location.origin).toString(),
          title: '첫 이력 찾기 하면 100% 당첨',
          description: '중고차 숨은 이력 찾기 첫 이용 고객 대상',
          button_text: '주유권 받으러 가기',
          image_width: 1380,
          image_height: 720,
          server_callback_args: {
            user_hash_id: event.user,
            environment: isProduction ? 'production' : 'development',
            event: 'gift_coupon',
          },
        });
      }
    };

    return (
      <>
        <a.div className={styles.content} style={contentStyle}>
          <div className={styles.header}>
            <Typography color={base_white} variant="Headline_3">
              {renderTitle()}
            </Typography>
            {eventStatus === 'init' && (
              <Typography variant="Body_1" color={base_white} className={styles.description}>
                {event?.category === 'fuel_coupon' ||
                event?.category === 'hob_fuel_coupon' ||
                event?.category === 'total_info_fuel_coupon'
                  ? '나는 얼마짜리 주유권을 받을까?'
                  : '행운의 선물은 어디에?'}
              </Typography>
            )}
            {event &&
              (event.end_policy === 'time' ? (
                event.promotion_end_at && <Timer endTime={event.promotion_end_at} className={styles.remainTime} />
              ) : (
                <Typography variant="Subtitle_2" color={brandcolor_primary}>
                  {event.participants_count === 0
                    ? '첫 번째로 이벤트에 참여해 보세요!'
                    : `벌써, ${event.participants_count.toLocaleString()}명이 받았어요!`}
                </Typography>
              ))}
          </div>

          <CardList
            carHashId={carHashId}
            coupons={event.coupons}
            flipCardIndex={flipCardIndex}
            setFlipCardIndex={setFlipCardIndex}
            eventStatus={eventStatus}
            openPhoneNumberInputPopup={openPhoneNumberInputPopup}
            isShareButtonClick={isShareButtonClick}
            category={event?.category}
          />
          {isVisibleShareButton() && (
            <Button
              text={eventStatus === 'shareRequired' ? '공유하고 한번 더!' : '친구에게 공유하기'}
              variant="primaryBrandcolor"
              className={styles.shareButton}
              rightIcon={<KakaoShare className={styles.kakaoIcon} />}
              onClick={handleKaKaoShare}
            />
          )}
        </a.div>
        <PhoneNumberInputPopup
          openBottomSheetDialog={isVisiblePhoneNumberInputPopup}
          onClose={() => setVisiblePhoneNumberInputPopup(false)}
          dimmer={dimmer}
          car={carHashId}
          event={event}
        />
      </>
    );
  };

  return (
    <Layout className={classNames(styles.layout, { [styles.flipped]: flipCardIndex !== -1 })}>
      <CardPageHeader isFlipped={flipCardIndex !== -1} />
      {renderContent()}
    </Layout>
  );
};

export default FlipCardPage;
